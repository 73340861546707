.heartBeat-animation {
    animation-name: MB-heartBeat;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-fill-mode: both;
  }


@keyframes MB-heartBeat {
    0% {
      transform: scale(1);
    }
    14% {
      transform: scale(1.3);
    }
    28% {
      transform: scale(1);
    }
    42% {
      transform: scale(1.3);
    }
    70% {
      transform: scale(1);
    }
  }


.commentNumber:hover {
    background-color: #E0E7FF;

  }
